@import 'src/styles/_variables';

.subscription {
	.ReactModal__Content {
		display: block;
		min-width: 300px;
	}

	@media only screen and (min-width: $tablet-width) {
		.ReactModal__Content {
			min-width: 550px;
		}
	}
}
