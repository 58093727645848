@import 'src/styles/_variables';

#tiptap-editor {
	margin-top: 10px;
	margin-bottom: 10px;
	@media only screen and (min-width: $tablet-width) {
		width: calc(100% + 24px);
		margin-left: -24px;
	}

	.ProseMirror p {
		line-height: 2.5pc;
		white-space: pre-wrap;
		min-height: 1.5em;
		margin-top: 15px;
		@media only screen and (min-width: $tablet-width) {
			padding-left: 24px;
		}
	}
	.ProseMirror:focus {
		outline: none;
	}
	.ProseMirror p.is-editor-empty:first-child::before {
		color: #adb5bd;
		content: attr(data-placeholder);
		height: 0;
		pointer-events: none;
		line-height: normal;
	}
	.ProseMirror p.is-editor-empty {
		margin: 10px 0px;
	}
	.ProseMirror h6 {
		@media only screen and (min-width: $tablet-width) {
			padding-left: 24px;
		}
		font-size: 28px;
		mark {
			background-color: transparent;
			color: #3b82f6;
		}
	}
	.ProseMirror p {
		mark {
			background-color: transparent;
			color: #3b82f6;
		}
	}

	[data-hover-menu] {
		color: #3b82f6;
	}

	&.v2 {
		.ProseMirror p {
			line-height: 2.1pc;
			font-size: 18px;
		}
		.ProseMirror h6 {
			font-size: 22px;
		}
	}
}

#styleButtonContainer {
	border-radius: 3px;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
	overflow: hidden;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: $white-color;

	.bubble-item {
		background-color: #fff;
		border: none;
		border-right: 1px dotted $light-color;
		text-align: center;
		color: $primary-color;
		font-size: 17px;
		position: relative;
		height: 35px;
		padding: 8px 0;
		margin-top: 0px !important;
		z-index: 1;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		width: 40px;

		&:hover,
		&.is-active {
			background-color: $secondary-color;
			cursor: pointer;
			.MuiSvgIcon-root {
				color: $primary-color;
			}
		}
		mark {
			background-color: $secondary-color;
		}
		.MuiSvgIcon-root {
			font-size: 22px;
		}
	}
	.bubble-item:last-child {
		border-right: none;
	}

	.header {
		padding: 8px 15px;
		width: max-content;
	}

	.ask-ai {
		padding: 8px 10px;
		width: 108px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		font-family: $secondary-font-family;
		.MuiSvgIcon-root {
			margin-left: 7px;
			color: $primary-color;
			width: 15px;
		}
	}
}

.ask-ai-dropdown-item {
	padding: 8px 15px;
	width: 319px;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	border-bottom: 1px dotted $light-color;

	.ask-ai-dropdown-item-value {
		font-size: 16px;
		font-family: $primary-font-family;
	}

	.ask-ai-dropdown-item-desc {
		font-size: 13px;
		font-family: $primary-font-family;
		color: $light-color;
	}

	&:hover {
		cursor: pointer;
		background-color: $secondary-color;
	}
	&:last-child {
		border-bottom: none;
	}
}
